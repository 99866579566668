<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Actualizar plan</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-5">
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-switch v-model="arriendo" item-value="arriendo" :label="`Plan de arriendo`"></v-switch>
              <v-text-field v-model="name" :rules="nameRules" :counter="190" prepend-icon="mdi-octagram" label="Nombre">
              </v-text-field>
              <v-select v-model="select" :items="services" item-text="name" item-value="id" prepend-icon="mdi-view-list"
                label="Seleccionar servicio *" persistent-hint return-object></v-select>
              <v-select v-model="select_billing" :items="billings" item-text="text" item-value="value"
                prepend-icon="mdi-table-clock" label="Seleccionar período *" :rules="billingRules"></v-select>
              <v-text-field :disabled="arriendo" v-model="price" :rules="priceRules" :counter="10" prefix="$"
                prepend-icon="mdi-cash-multiple" label="Precio *"></v-text-field>
              <v-text-field :disabled="!arriendo" v-model="priceUf" :rules="priceRules" :counter="10" prefix="$"
                prepend-icon="mdi-cash-multiple" label="Precio UF"></v-text-field>
              <v-textarea v-model="description" :rules="descriptionRules" :counter="190"
                prepend-icon="mdi-format-list-group" label="Descripción *"></v-textarea>
            </v-col>
            <v-col cols="12" md="3"> </v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <div class="text-right pr-10">
                <v-btn color="primary" class="mr-4" @click="cancel" outlined width="130">
                  <v-icon>mdi-close-circle</v-icon> Cancelar
                </v-btn>
                <v-btn color="primary" @click="update" outlined width="130">
                  <v-icon>mdi-sync-circle</v-icon> Actualizar
                </v-btn>
              </div>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    arriendo: true,
    services: [],
    name: "",
    description: "",
    price: "",
    priceUf: "",
    select: "",
    select_billing: "",
    billing: "",
    billings: [
      { text: "Mensual", value: "mensual" },
      { text: "Semestral", value: "semestral" },
      { text: "Anual", value: "anual" },
      { text: "Bienal", value: "bienal" },
      { text: "Trienal", value: "trienal" },
    ],
    descriptionRules: [
      (v) =>
        (v.length <= 500) || "El campo no debe exceder de 500 caracteres",
    ],
    nameRules: [
      v => !!v || 'El nombre es requerido',
      v => v && v.length <= 25 || 'El nombre de plan no debe exceder los 25 caracteres',
    ],

    serviceRules: [
      v => !!v || 'El servicio es requerido'
    ],
    billingRules: [
      v => !!v || 'El período es requerido'
    ],

    priceRules: [
      v => !!v || 'El precio es requerido',
      v => v >= 0 || 'El valor precio debe ser mayor o igual a cero',
    ],
    priceUfRules: [
      v => !!v || 'El precio uf es requerido',
      v => v >= 0 || 'El valor precio debe ser mayor o igual a cero',
    ],
  }),

  mounted() {
    this.getServices();
    this.chargeData();
  },
  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "plans" }), 10);
    },
    getService(id_service) {
      let service = this.services.find(
        (service) => (service.id_service = id_service)
      );
      return service.id_service;
    },

    chargeData() {
      console.log("cargando datos");
      let request = {
        id: this.$route.params.id,
      };
      axios
        .post("/plan", request)
        .then((response) => {
          console.log(response.data);
          this.price = response.data.price.toString();
          this.description = response.data.description;
          this.name = response.data.name;
          this.id_service = response.data.id_service;
          this.id = response.data.id;
          this.priceUf = response.data.price_uf;
          this.select = this.getService(this.id_service);
          this.select_billing = response.data.billing;
          this.arriendo=response.data.arriendo;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "");
        });
    },
    update() {
      console.log("actualizando plan");
      console.log(this.select);
      let request = {
        name: this.name,
        description: this.description,
        price: this.price,
        id_service: this.select.id == undefined ? this.id_service : this.select.id,
        price_uf: this.priceUf,
        billing: this.select_billing,
        id: this.$route.params.id,
        arriendo:this.arriendo
      };
      console.log(request);
      if (this.select.id != "") {
        axios
          .post("/update-plan", request)
          .then((response) => {
            this.displayNotification("success", "Éxito", "Plan actualizado correctamente");
            console.log(response.data);
            setTimeout(() => this.$router.push({ name: "plans" }), 4000);
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", "No se pudo actualizar el plan");
            //setTimeout(() => this.$router.push({ name: "plans" }), 4000);
          });
      } else {
        this.displayNotification(
          "error",
          "Error",
          "Debe seleccionar un servicio"
        );
      }
    },
    getServices() {
      axios
        .get("/services")
        .then((response) => {
          console.log(response.data);
          this.services = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener servicios");
        });
    },
    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log("billing periods");
          console.log(response.data);
          this.billing_periods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener servicios");
        });
    },
  },
};
</script>